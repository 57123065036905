import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const LocationContext = React.createContext();

export function LocationProvider({ children, data }) {
    const [previousLocation, setPreviousLocation] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(useLocation());
    const [sameLocation, setSameLocation] = useState(false);
    const [filters, setFilters] = useState(() => {
        // Load filters from localStorage if they exist
        const savedFilters = localStorage.getItem('filters');
        return savedFilters ? JSON.parse(savedFilters) : [];
    });

    const location = useLocation();

    useEffect(() => {
        const basePath = currentLocation.pathname.split('/').slice(0, 2).join('/');
        setPreviousLocation(basePath); // Store the previous location
        setCurrentLocation(location); // Update the current location
        setSameLocation(basePath === location?.pathname);
    }, [location]);

    useEffect(() => {
        // Save filters to localStorage whenever they change
        localStorage.setItem('filters', JSON.stringify(filters));
    }, [filters]);

    return (
        <LocationContext.Provider value={{ previousLocation, currentLocation, sameLocation, setFilters, filters }}>
            {children}
        </LocationContext.Provider>
    );
}
