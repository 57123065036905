export const Authority = {
  Sprint_Tracking_Management: "SP00",
  Home: "H000",
  Management: "M000",
  Reports: "RP00",
  Retro_Report: "RP01",
  Sprint_Report: "RP02",
  Settings: "ST00",
  User_Management: "U000",
  User_List: "U001",
  User_Detail: "U002",
  User_Delete: "U003",
  User_Insert: "U004",
  User_Password_Update: "U005",
  Authority_Management: "A000",
  Authority_List: "A001",
  Authority_Detail: "A002",
  Authority_Delete: "A003",
  Authority_Insert: "A004",
  Authority_Update: "A005",
  Agreement_Management: "AG000",
  Agreement_Detail: "AG004",
  Agreement_Edit: "AG003",
  Agreement_Insert: "AG002",
  Agreement_List: "AG001",
  Role_Management: "R000",
  Role_List: "R001",
  Role_Detail: "R002",
  Role_Delete: "R003",
  Role_Insert: "R004",
  Role_Update: "R005",
  Project_Management: "P000",
  Project_List: "P001",
  Project_Detail: "P002",
  Project_Delete: "P003",
  Project_Insert: "P004",
  Project_Update: "P005",
  Sprint_Management: "S000",
  Sprint_List: "S001",
  Sprint_Detail: "S002",
  Sprint_Delete: "S003",
  Sprint_Insert: "S004",
  Sprint_Update: "S005",
  Sprint_Task_Insert: "S007",
  Sprint_Task_Update: "S008",
  Team_Management: "T000",
  Team_List: "T001",
  Team_Detail: "T002",
  Team_Delete: "T003",
  Team_Insert: "T004",
  Team_User_Insert: "T005",
  Team_User_Update: "T006",
  Team_User_Delete: "T007",
  Company_Management: "C000",
  Company_List: "C001",
  Company_Detail: "C002",
  Company_Delete: "C003",
  Company_Insert: "C004",
  Company_Update: "C005",
  Title_Management: "TT00",
  Title_List: "TT01",
  Title_Detail: "TT02",
  Title_Delete: "TT03",
  Title_Insert: "TT04",
  Title_Update: "TT05",
  SprintNumbers: "S006",
  Staff_Management: "ST00",
  Staff_Insert: "ST01",
  Staff_Update: "ST02",
  Staff_List: "ST03",
  Staff_Detail: "ST04",
  Customer_Management: "CS00",
  Customer_Insert: "CS01",
  Customer_Update: "CS02",
  Customer_List: "CS03",
  Customer_Detail: "CS04",
  Retro_Management: "RT000",
  Retro_Insert: "RT004",
  Retro_Update: "RT005",
  Retro_List: "RT001",
  Retro_Detail: "RT002",
  Retro_Add_Report: "RT006",
  Retro_Report_List: "RT007",
  Retro_Update_Report: "RT009",
  Customer_Raiting_Insert: "RT008",
  Retro_Report_Detail: "RT0010",

  Customer_Comment_List: "CM01",
  ProductOwner_Comment_List: "CM02",
  ScrumMaster_Comment_List: "CM03",
  Comment_Update: "CM06",
  Comment_Insert: "CM05",

  Company_Management_Parent: "CM00",

  Progress_Payment: "PP000",
  Progress_Payment_Detail: "PP002",
  Progress_Payment_List: "PP001",
  Progress_Payment_Update: "PP003",

  ExpenseManagement: "EX000",
  ExpenseDetail: "EX001",
  ExpenseList: "EX002",
  ExpenseInsert: "EX003",
  ExpenseUpdate: "EX004",
  ExpenseDelete: "EX005",


  Dashboard: "DSH000",


  //Invoice
  Invoice_Management: "IN000",
  Invoice_List: "IN001",
  Invoice_Detail: "IN002",
  Invoice_Insert: "IN003",
  Invoice_Upload: "IN004",
  Invoice_Update: "IN005",
  Invoice_Status_Update: "IN006",
  Invoice_File: "IN007",
  Invoice_Delete:"IN005",

  //InvoicePeriod
  InvoicePeriod_Management: "IP000",
  InvoicePeriod_Detail: "IP001",

 //Kdv
 Kdv_Management: "KDV000",
 Kdv_List: "KDV001",
 Kdv_Insert: "KDV002",
 Kdv_Update: "KDV003",

 //Currency
 Currency_Management: "CUR000",
 Currency_Insert: "CUR001",
 Currency_Update: "CUR002",
 Currency_Delete: "CUR003",
 Currency_List: "CUR004",
 Currency_Detail: "CUR005",

 //ExchangeRate
 ExchangeRate_Management:"ER000",
 ExchangeRate_Insert: "ER001",
 ExchangeRate_Update: "ER002",
 ExchangeRate_Delete: "ER003",
 ExchangeRate_List: "ER004",
 ExchangeRate_Detail: "ER005",

 //UserTerm
 UserTerm_Management: "UT000",
 UserTerm_List: "UT001",
 UserTerm_Update:"UT002",

 //SalesType
 SalesType_List:"UT002",
 SalesType_Insert:"SLS002",
 SalesType_Update:"PP003",
 SalesType_Detail:"PP004"
};
